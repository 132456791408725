// Translate
// Migrated
<template>
  <button
    :id="id"
    class="charter-search__btn"
    :class="{ active }"
    :aria-expanded="active"
    aria-haspopup="true"
    :aria-labelledby="`${id}Label`"
    @click="$emit('click')"
  >
    <div
      v-if="!slim || loading"
      :id="`${id}Label`"
      class="charter-search__btn-title flex w-full"
    >
      <template v-if="loading">
        <b-skeleton
          class="mr-2 inline-block"
          height="16px"
          width="16px"
        />
        <b-skeleton
          class="inline-block"
          height="16px"
          width="60px"
        />
      </template>
      <template v-else>
        <span
          v-if="!$isSol && hasIcon"
          class="mr-2"
        >
          <slot name="icon" />
        </span>
        <slot name="label" />
        <fa
          v-if="$isSol"
          class="charter-search__btn-icon ml-auto transition-transform"
          icon="chevron-down"
          :style="{ transform: `rotateX(${active ? '180deg' : '0'})` }"
        />
      </template>
    </div>
    <div class="charter-search__btn-text">
      <template v-if="loading">
        <div style="width: 65%">
          <b-skeleton
            width="100%"
            height="24px"
          />
          <b-skeleton
            v-if="id === 'charterButtonGuests'"
            width="80%"
            height="16px"
          />
        </div>
        <b-skeleton
          width="20px"
          height="24px"
        />
      </template>
      <template v-else>
        <slot name="content" />
        <fa
          v-if="!$isSol"
          class="charter-search__btn-icon print-hidden"
          :icon="indicator"
        />
      </template>
    </div>
    <template v-if="!loading">
      <slot name="append" />
    </template>
  </button>
</template>

<script>
export default defineNuxtComponent({
  props: {
    active: {
      type: Boolean,
      default: false,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    slim: {
      type: Boolean,
      default: false,
    },

    id: {
      type: String,
      required: true,
    },
  },

  emits: ['click'],

  computed: {
    indicator () {
      const suffix = this.active ? 'up' : 'down'
      return `chevron-${suffix}`
    },

    hasIcon () {
      if (this.slim) {
        return false
      }
      return slotHasContent('icon', this.$slots)
    },
  },
})
</script>
