// Translate
// Migrated
<template>
  <CalendarSearchBarButton
    id="charterButtonGuests"
    ref="charterButtonGuests"
    :active="active"
    :loading="loading"
    :slim="slim"
    @click="$emit('setActive')"
  >
    <template #icon>
      <fa :icon="icons.faUserFriends" />
    </template>
    <template #label>
      <span data-i18n="charterSearchGuests">
        {{ $t('charterSearchGuests') }}
      </span>
    </template>
    <template #content>
      <span
        v-if="kids === 0"
        data-i18n="chooseRoomTypeAdults"
      >{{ $t('chooseRoomTypeAdults', adults) }}<small
        v-if="!selectChildAges"
        class="block"
        data-i18n="charterSearchGuestNumberRooms"
      >{{ $t('charterSearchGuestNumberRooms', rooms) }}</small>
      </span>
      <span v-else>
        <i18n-t
          data-i18n="charterSearchGuestNumber"
          keypath="charterSearchGuestNumber"
          tag="span"
        >
          <template #adults>
            <span data-i18n="chooseRoomTypeAdults">
              {{ $t('chooseRoomTypeAdults', adults) }}
            </span>
          </template>
          <template #children>
            <span data-i18n="chooseRoomTypeChildren">
              {{ $t('chooseRoomTypeChildren', kids) }}
            </span>
          </template>
        </i18n-t>
        <small
          v-if="!selectChildAges"
          class="block text-medium-gray"
          data-i18n="charterSearchGuestNumberRooms"
        >{{ $t('charterSearchGuestNumberRooms', rooms) }}</small>
      </span>
    </template>
    <template #append>
      <div
        v-if="selectChildAges && !(windowWidth <= 992 && active)"
        class="charter-search__btn-title text-error charter-search__error__childs-ages blink blink-title z-[1000] pt-0 sm:pt-1"
        data-i18n="charterSelectChildAges"
      >
        {{ $t('charterSelectChildAges') }}
      </div>
    </template>
  </CalendarSearchBarButton>
</template>

<script>
import { faUserFriends } from '@fortawesome/pro-duotone-svg-icons'
import { mapState } from 'pinia'

export default defineNuxtComponent({
  props: {
    active: {
      type: Boolean,
      default: false,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    slim: {
      type: Boolean,
      default: false,
    },

    selectChildAges: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['setActive'],

  data () {
    return {
      icons: {
        faUserFriends,
      },

      windowWidth: 0,
    }
  },

  computed: {
    ...mapState(useCharterPackagesStore, {
      peoplePerRoom: 'peoplePerRoom',
    }),

    kids () {
      return this.peoplePerRoom.children
    },

    adults () {
      return this.peoplePerRoom.adults
    },

    rooms () {
      return this.peoplePerRoom.rooms
    },
  },

  mounted () {
    window.addEventListener('resize', this.resizeListener)

    this.windowWidth = window.innerWidth
  },

  beforeUnmount () {
    window.removeEventListener('resize', this.resizeListener)
  },

  methods: {
    resizeListener () {
      this.windowWidth = window.innerWidth
    },
  },
})
</script>
